@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;500&display=swap');

:root {
    --font-family: 'Inter', sans-serif;
    --btn-background-color: #EB5D1E;
    --btn-border-color: #EB5D1E;
    --btn-color: #ffffff;
}

h3 {
    font-family: var(--font-family);
}

.btn {
    background-color: var(--btn-background-color) !important;
    border-color: var(--btn-border-color) !important;
    color: var(--btn-color) !important;
    font-family: var(--font-family);
    font-weight: 200;
}

.pointer {
    cursor: pointer;
}

.hidden-word {
    border-color: red !important;
}